import { SubmitHandler, useForm } from 'react-hook-form';
import './contact-me.scss';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

function ContactUs() {
	type FormValues = {
		fullName: string;
		email: string;
		message: string;
	};
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FormValues>();

	const onSubmit: SubmitHandler<FormValues> = (data) => {
		MySwal.fire({
			title: 'Sending',
			icon: 'info',
			allowOutsideClick: false,
			allowEscapeKey: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});

		emailjs
			.sendForm(
				'mitchell_chapman_new',
				'template_xaNDDWrT',
				'#contactForm',
				'user_hagNWLR2KVvcQWGliwRek'
			)
			.then(
				(result: any) => {
					MySwal.hideLoading();
					MySwal.fire({
						title: 'Sent',
						icon: 'success',
					});
				},
				(error: any) => {
					MySwal.hideLoading();
					MySwal.fire({
						title: 'Error',
						icon: 'error',
						text: 'Something went wrong:' + error.text,
					});
				}
			);
	};

	return (
		<div className="ContactPageOuterContainer" id="contact-me">
			<div className="ContactPageHeader">
				<h1>Contact Me</h1>
			</div>
			<form onSubmit={handleSubmit(onSubmit)} id="contactForm">
				<span>Full Name</span>
				<input
					{...register('fullName', {
						required: 'Please enter your full name',
						pattern: {
							value: /[a-zA-Z- ]+/,
							message: 'Please enter a valid name',
						},
					})}
					name="fullName"
					title="fullName"
					placeholder="Full Name"
				/>
				<p>{errors.fullName?.message}</p>
				<span>Email</span>
				<input
					{...register('email', {
						required: 'Please enter your email address',
						pattern: {
							value:
								// eslint-disable-next-line no-empty-character-class
								/([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@([0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)*|\[((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|IPv6:((((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){6}|::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){5}|[0-9A-Fa-f]{0,4}::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){4}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):)?(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){3}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,2}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){2}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,3}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,4}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,5}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,6}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)|(?!IPv6:)[0-9A-Za-z-]*[0-9A-Za-z]:[!-Z^-~]+)])/,
							message: 'Please enter a valid email address',
						},
					})}
					name="email"
					title="email"
					placeholder="Email"
				/>
				<p>{errors.email?.message}</p>
				<span>Message</span>
				<textarea
					{...register('message', {
						required: 'Please enter a message',
					})}
					name="message"
					title="message"
					placeholder="Message"
				/>

				<p>{errors.message?.message}</p>

				<input type="submit" value={'Send Email'} />
			</form>
		</div>
	);
}

export default ContactUs;
