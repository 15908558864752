import './project.scss';
import Portfolio from '../../Assets/Portfolio.png';
import Mitchle from '../../Assets/Mitchle.png';
import PasswordManager from '../../Assets/PasswordManager.png';
import WorkInProgress from '../../Assets/WorkInProgress.png';
import ClassDojo from '../../Assets/ClassDojo.png';

function Project({
	projectName,
	contentStyle,
	projectDescription,
}: {
	projectName: string;
	contentStyle: string;
	projectDescription: string;
}) {
	const projectMap: any = {
		portfolio: Portfolio,
		mitchle: Mitchle,
		'password manager': PasswordManager,
		'nfl website': WorkInProgress,
		'class dojo': ClassDojo
	};

	return (
		<div className="ProjectOuterContainer">
			{contentStyle === 'left' ? (
				<div className="ProjectInnerContainer">
					<div className="ProjectDescriptionContainerLeft">
						<h2>{projectName.toUpperCase()}</h2>
						<p>{projectDescription}</p>
					</div>
					<div className="ProjectImageContainer">
						<img src={projectMap[projectName]} alt={`${projectName}`} />
					</div>
				</div>
			) : (
				<div className="ProjectInnerContainer">
					<div className="ProjectImageContainer">
						<img src={projectMap[projectName]} alt={`${projectName}`} />
					</div>
					<div className="ProjectDescriptionContainerRight">
						<h2>{projectName.toUpperCase()}</h2>
						<p>{projectDescription}</p>
					</div>
				</div>
			)}
		</div>
	);
}

export default Project;
