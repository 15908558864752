import Project from '../Project/project';
import {
	PortfolioDescription,
	MitchleDescription,
	PasswordManagerDescription,
	ClassDojoDescription,
} from '../Project/project-descriptions';
import './projects-page.scss';

function ProjectsPage() {
	return (
		<div className="ProjectsPageOuterContainer">
			<div className="ProjectsPageHeader">
				<h1>Projects</h1>
			</div>
			<div className="LargeScreenProjectsContainer">
				<Project
					projectName={'portfolio'}
					contentStyle={'right'}
					projectDescription={PortfolioDescription()}
				/>
				<Project
					projectName={'mitchle'}
					contentStyle={'left'}
					projectDescription={MitchleDescription()}
				/>
				<Project
					projectName={'password manager'}
					contentStyle={'right'}
					projectDescription={PasswordManagerDescription()}
				/>
				<Project
					projectName={'class dojo'}
					contentStyle={'left'}
					projectDescription={ClassDojoDescription()}
				/>
			</div>

			<div className="MobileScreenProjectsContainer">
				<Project
					projectName={'portfolio'}
					contentStyle={'right'}
					projectDescription={PortfolioDescription()}
				/>
				<Project
					projectName={'mitchle'}
					contentStyle={'right'}
					projectDescription={MitchleDescription()}
				/>
				<Project
					projectName={'password manager'}
					contentStyle={'right'}
					projectDescription={PasswordManagerDescription()}
				/>
				<Project
					projectName={'class dojo'}
					contentStyle={'right'}
					projectDescription={ClassDojoDescription()}
				/>
			</div>
		</div>
	);
}
export default ProjectsPage;
