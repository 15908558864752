import './section-separator.scss';

function SectionSeparator() {
	return (
		<div className="SeparatorContainer">
			<div className="Separator"></div>
		</div>
	);
}

export default SectionSeparator;
