import Project from '../Project/project';
import { NFLWebsiteDescription } from '../Project/project-descriptions';
import './current-projects.scss';

function CurrentProjects() {
	return (
		<div className="CurrentProjectsPageOuterContainer" id="projects">
			<div className="CurrentProjectsPageHeader">
				<h1>Current Projects</h1>
			</div>
			<div className="LargeScreenProjectsContainer">
				<Project
					projectName={'nfl website'}
					contentStyle={'right'}
					projectDescription={NFLWebsiteDescription()}
				/>
			</div>
			<div className="MobileScreenProjectsContainer">
				<Project
					projectName={'nfl website'}
					contentStyle={'right'}
					projectDescription={NFLWebsiteDescription()}
				/>
			</div>
		</div>
	);
}

export default CurrentProjects;

/*
    Ideas/Upcoming/In progress
    NFL website/api
    JWT authentication or something similar
    Something for frontend skills like react mini project
    Something for backend skills like .NET mini project
    RabbitMQ project
    Film Database - Can be using dummy data
*/

/*
	To do 

	Visual feedback that someone has clicked the send email button 

*/
