export const PortfolioDescription = (): string => {
	return 'I created my portfolio using React. By creating my portfolio from the ground up it allowed me full control over what I wanted it to look like. As a Software Engineer with more backend experience being able to use something like React to make my portfolio is ideal.';
};

export const MitchleDescription = () => {
	return 'Mitchle is a wordle clone which like wordle will validate your submitted answer against an unknown word. The front end of this application was made using Angular and the backend was made using .NET Core 6. The user will get feedback on their word as to whether letters are in the correct place (green), letters are in the word but in the wrong place (yellow) or letters are incorrect (white).';
};

export const PasswordManagerDescription = () => {
	return 'This project is a command line password manager in which the user can create and delete passwords. The app can also generate passwords for the user. The tech stack used for this application is .NET Framework 4.7.2 (migration underway to .NET 8) and the database is MongoDB to store the passwords.';
};

export const NFLWebsiteDescription = () => {
	return 'This project was created as I also had trouble finding NFL fixtures without having to make too many clicks on a website or typing something too specific into a website. The frontend of this application is using React and the backend of this application is being written in .NET 8.0. This application will scout the fixtures for the next NFL games in a 30 day period and store them in a cache that will make the request after 15 days to keep the information up to date.';
};

export const ClassDojoDescription = () => {
	return 'This project is a Class Dojo website clone that is capable of adding points to users in the class for good behaviour. Removing points and bulk adding points is also supported. The tech stack used for this application is .NET Core 6.0 for the backend service and the frontend is using Angular. The information for the points and the names of the users are stored in MongoDB.';
}