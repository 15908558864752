import NavigationHeader from '../NavigationHeader/navigation-header';
import './home-page.scss';

function HomePage() {
	return (
		<div className="HomePageOuterContainer">
			<NavigationHeader />
			<div className="HomePageTitleOuterContainer">
				<div className="HomePageTitleContainer">
					<h1>Mitchell Chapman</h1>
					<h1>Software Developer</h1>
				</div>
			</div>
		</div>
	);
}

export default HomePage;
