import SkillCard from '../SkillCard/skill-card';
import './skills-page.scss';

function SkillsPage() {
	return (
		<div className="SkillsPageOuterContainer">
			<div className="SkillsPageHeader">
				<h1>Skills</h1>
			</div>
			<div className="SkillsContainer">
				<SkillCard name={'dotnet core'} />
				<SkillCard name={'csharp'} />
				<SkillCard name={'azure'} />
				<SkillCard name={'azure devops'} />
				<SkillCard name={'azure functions'} />
				<SkillCard name={'reactjs'} />
				<SkillCard name={'vuejs'} />
				<SkillCard name={'mysql'} />
				<SkillCard name={'html'} />
				<SkillCard name={'css'} />
				<SkillCard name={'sass'} />
				<SkillCard name={'angular'} />
				<SkillCard name={'git'} />
				<SkillCard name={'rest apis'} />
			</div>
		</div>
	);
}

export default SkillsPage;
