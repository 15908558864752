import './App.scss';
import HomePage from './Components/HomePage/home-page';
import AboutPage from './Components/AboutPage/about-page';
import Separator from './Components/SectionSeparator/section-separator';
import SkillsPage from './Components/SkillsPage/skills-page';
import ProjectsPage from './Components/ProjectsPage/projects-page';
import CurrentProjects from './Components/CurrentProjects/current-projects';
import ContactMe from './Components/ContactMe/contact-me';

function App() {
	return (
		<div className="App">
			<HomePage />
			<Separator />
			<AboutPage />
			<Separator />
			<SkillsPage />
			<Separator />
			<CurrentProjects />
			<Separator />
			<ProjectsPage />
			<Separator />
			<ContactMe />
		</div>
	);
}

export default App;
