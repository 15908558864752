import './navigation-header.scss';

function NavigationHeader() {
	return (
		<div className="NavigationBarOuterContainer">
			<div className="NavigationBarInnerContainer">
				<nav>
					<ul>
						<li>
							<a href="#home">Home</a>
						</li>
						<li>
							<a href="#about">About</a>
						</li>
						<li>
							<a href="#projects">Projects</a>
						</li>
						<li>
							<a href="#contact-me">Contact</a>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	);
}

export default NavigationHeader;
